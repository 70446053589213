.carousel-caption{
    color: #4f4f4f;
    position: relative; /* Change the captions position from absolute to relative */
    top: 0;
    left: 0;
}



.carousel-indicators{
    bottom: 120px;
}

.carousel-indicators li{
        width: 1rem;
        height: 1rem;
        border: 1px transparent;
        border-radius: 100%;
        background-color: #E0E0E0;
}

.carousel-indicators .active {
    background-color: #000000;
}


.text-center {
  text-align: center;
}

